<template>
  <div>
    <nav-bar/>
    <Main v-if="step===1" @done="next"/>
    <PDFView v-if="step===2" @done="prev" :preview="preview" :numPages="numPages"/>
    <Comments v-if="step===2"/>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import NavBar from '../components/consultation/NavBar';
import Main from '../components/consultation/Main';
import PDFView from '../components/consultation/PDFView';
import Comments from '../components/consultation/Comments';
import axcessApi from "@/plugins/axcessApi";
export default {
  components: {
    NavBar,
    Main,
    PDFView,
    Comments
  },

  data() {
    return {
      uuid: this.$route.params.uuid,
      step: 1,
      preview: undefined,
      numPages: 0
    };
  },

  async created() {
    if (this.uuid) {
      this.validateUUID();
    }
  },

  methods: {
    next(newValue) {
      this.preview = newValue.preview;
      this.numPages = newValue.numPages;
      this.step ++;
    },

    prev() {
      this.step = 1;
    },

    async validateUUID() {
      try {
        const response = await axcessApi.postRequest('/validatelink', {
              'uuid': this.uuid
        })
        this.validateLink(response.data);
      } catch (error) {
        this.$router.push({name: 'error'});
      }
    },
 
    ...mapActions('consultation', ['validateLink'])
  }
};
</script>

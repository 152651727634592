<template>
  <v-content v-if="consult">
    <v-container  fluid >
        <v-card v-for="item in consult" :key="item.id" class="mx-auto mb-1" outlined max-width="900">
          <v-list-item three-line>
            <v-list-item-content>
              <div>Request ID</div>
              <p class="subtitle-2">{{item.req_code}}</p>
              <div>Details</div>
              <div class="caption">
                <span
                  class="font-weight-black"
                >Send date:</span>
                {{formatDate(item.sent_out_date)}} |
                <span
                  class="red font-weight-black"
                >Due date:</span>
                {{formatDate(item.due_back_date)}}
              </div>
              <hr />
              <div>Sender</div>
              <div class="caption capitalize">
                {{item.sender.username}} |
                <span class="lowercase">{{item.sender.email}}</span>
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-card-actions>
            <v-btn class="custom-color-accent-text" @click="calldownload(item.id)">{{$t('Open Document')}}</v-btn>
          </v-card-actions>
        </v-card>
    </v-container>
     <v-dialog v-model="downloaddialog" max-width="500px">
      <v-card>
        <v-card-title>Consultation Package</v-card-title>
            <v-container style="height: 400px;" v-if="print">
                <v-row
                    class="fill-height"
                    align-content="center"
                    justify="center"
                >
                    <v-col
                    class="subtitle-1 text-center"
                    cols="12"
                    >
                    Downloading package for consultation
                    </v-col>
                    <v-col cols="6">
                    <v-progress-linear
                        color="deep-purple accent-4"
                        indeterminate
                        rounded
                        height="6"
                    ></v-progress-linear>
                    </v-col>
                </v-row>
            </v-container>
      </v-card>
    </v-dialog>
  </v-content>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import moment from "moment";
import axios from "axios";
export default {
  
  data() {
      return {
          currentPage: 1,
          preview: undefined,
          downloaddialog: false
      }
  },

  computed: {
    ...mapGetters("consultation", {
      consult: "filterconsult"
    }),
    ...mapState("documents", {
      print: "print"
    })
  },

  methods: {
    async calldownload( request_id ){
      this.downloaddialog = true;
          
        var response = await this.printConsultant(request_id)
        
        if(response.status == 200)
        this.downloaddialog = false;
    },
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },

    async openDocument(item) {

      this.setItemId(item.doc_id);
      try {
        this.preview = `${axios.defaults.baseURL}/markup/${item.doc_id}/highlight`;
        this.retrieveDocInfo(item.doc_id);
        this.$emit('done', {
            preview: this.preview,
            numPages: item.consult_page_count
        });
      } catch (error) {
        this.error = error;
        this.errored;
      }
    },

    ...mapActions('consultation', ['retrieveDocInfo', 'setItemId']),
    ...mapActions('documents', ['printConsultant'])
  }
};
</script>

<style lang="scss" scoped>
.capitalize {
  text-transform: capitalize;
}
.lowercase {
  text-transform: lowercase;
}
</style>